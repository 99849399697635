import _utils from "./../utils";
var exports = {};
var utils = _utils;
/**
 * Determines whether the payload is an error thrown by Axios
 *
 * @param {*} payload The value to test
 * @returns {boolean} True if the payload is an error thrown by Axios, otherwise false
 */

exports = function isAxiosError(payload) {
  return utils.isObject(payload) && payload.isAxiosError === true;
};

export default exports;